<template>
  <div v-if="plan">
    <h1 class="headline--md text-bold text-uppercase mb-16">edit {{ plan.title }}</h1>

    <fund-form
      :plan="planEditable"
      @update-plan="onUpdatePlan($event)"
      @delete-plan="onDeletePlan"
      @cancel="onCancelUpdate"
    />
  </div>
</template>

<script>
import { mapActions, mapState, mapMutations } from 'vuex'
import { UPDATE_PLAN_SUCCESS, CONFIRM_BEFORE_LEAVE, DELETE_PLAN_SUCCESS } from '@/store/support/message'
import { deepCopy } from '@/utils/json-parser'
const FundForm = () => import('@/components/FundForm')

export default {
  components: {
    FundForm
  },

  computed: {
    ...mapState('crowdfunding', ['plan']),

    routeParams() {
      const params = this.$route.params

      return {
        programId: params.programId,
        planId: params.planId
      }
    },

    planEditable() {
      const planEditable = deepCopy(this.plan)
      delete planEditable.smart_contract
      delete planEditable.description
      delete planEditable.id
      delete planEditable.recommended
      delete planEditable.smart_contract_id
      delete planEditable.program_id

      return planEditable
    }
  },

  methods: {
    ...mapActions('crowdfunding', ['getCrowdfundingPlan', 'updateCrowdfundingPlan', 'deleteCrowdfundingPlan']),

    ...mapMutations('crowdfunding', ['CLEAR_CROWDFUNDING_PLAN']),

    ...mapMutations('alert', ['STORE_ALERT']),

    getUpdateDataRequest(plan) {
      return {
        ...plan,
        smart_contract_id: this.plan.smart_contract_id,
        program_id: this.$route.params.programId
      }
    },

    onUpdatePlan(plan) {
      const payload = {
        planId: this.$route.params.planId,
        data: this.getUpdateDataRequest(plan)
      }
      this.updateCrowdfundingPlan(payload).then(() => {
        this.$router.push({ name: 'CrowdfundingPlan' })
        this['STORE_ALERT']({ type: 'success', content: UPDATE_PLAN_SUCCESS, timeOut: 1600 })
      })
    },

    onDeletePlan() {
      const payload = {
        planId: this.$route.params.planId,
        programId: this.$route.params.programId
      }
      this.deleteCrowdfundingPlan(payload).then(() => {
        this.$router.push({ name: 'CrowdfundingPlans' })
        this['STORE_ALERT']({ type: 'success', content: DELETE_PLAN_SUCCESS, timeOut: 1600 })
      })
    },

    onCancelUpdate() {
      window.history.length > 1 ? this.$router.go(-1) : this.$router.push('/')
    }
  },

  created() {
    this['CLEAR_CROWDFUNDING_PLAN']()
    this.getCrowdfundingPlan(this.routeParams)
  },

  beforeRouteLeave(to, from, next) {
    if (to.name === 'CrowdfundingPlan' || to.name === 'CrowdfundingPlans') {
      next()
    } else {
      const answer = window.confirm(CONFIRM_BEFORE_LEAVE)
      if (answer) {
        next()
      } else {
        next(false)
      }
    }
  }
}
</script>
